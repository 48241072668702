import sp from './sandbox.preview.json';
import sc from './sandbox.cdn.json';
import dp from './dev.preview.json';
import dc from './dev.cdn.json';
import cdp from './corp.dev.preview.json';
import cdc from './corp.dev.cdn.json';
import cpp from './corp.prod.preview.json';
import cpc from './corp.prod.cdn.json';
import up from './uat.preview.json';
import uc from './uat.cdn.json';
import stp from './sit.preview.json';
import stc from './sit.cdn.json';
import pp from './prod.preview.json';
import pc from './prod.cdn.json';

interface Obj {
    [index:string] : typeof pc
}

const properties:Obj = {
    'sandbox.preview' : sp,
    'sandbox.cdn' : sc,
    'dev.preview' : dp,
    'dev.cdn' : dc,
    'corp.dev.preview' : cdp,
    'corp.dev.cdn' : cdc,
    'corp.prod.preview' : cpp,
    'corp.prod.cdn' : cpc,
    'uat.preview' : up,
    'uat.cdn' : uc,
    'sit.preview' : stp,
    'sit.cdn' : stc,
    'prod.preview' : pp,
    'prod.cdn' : pc,
    // OLD TO SUPPORT CURRENT SETUP TO BE DELETED
	'sandbox-mgmt' : sp,
	'sandbox-delv' : sc,
	'dev-mgmt' : dp,
	'dev-delv' : dc,
	'uat-mgmt' : up,
	'uat-delv' : uc,
	'prod-mgmt' : pp,
	'prod-delv' : pc,
};

export let environment = process.env.npm_config_env || process.env.NEXT_PUBLIC_ENV || 'sandbox.preview';
export default properties[environment];

export function getGlobalProps(env:string) {
    return properties[env];
}

export function setGlobalProps(env:string) {
    environment = env;
}