import pino from 'pino';
import globalProperties from '@/../properties/global';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const loglevel = process.env.npm_config_log || process.env.NEXT_PUBLIC_LOG || 'INFO';

const mixin = {
    env: globalProperties.envName,
    port: process.env.npm_config_port || process.env.CONTAINER_PORT,
    nodename:  process.env.npm_config_nodename || process.env.NODE_NAME
}

const pinoConfig = {
    mixin() {
        return mixin;
    },
    timestamp: () => {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        return `,"EasternTime":"${dayjs(new Date()).tz("America/New_York").format('DD/MM/YYYY HH:mm:ss-SSS') }"`
    },
    formatters: {
        level: (label:string) => {
            return {
                level: label.toUpperCase()
            }
        }
    }
}

let pinoLogger = pino();

if(typeof window === "undefined") {
    if(process.env.NODE_ENV !== 'development') {
        pinoLogger = pino(pinoConfig, pino.destination('./logs/app.log'))
    } else {
        pinoLogger = pino(pinoConfig)
    }
}

const levels: { [index: string]: number } = {
    ALL: 0,
    TRACE: 10,
    DEBUG: 20,
    INFO: 30,
    WARN: 40,
    ERROR: 50,
    FATAL: 60,
    OFF: 70,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
function argsToString(...args: Array<any[]>) {
    const msg = args[0].map((arg) => (typeof arg === 'string' ? arg : JSON.stringify(arg)));
    return msg.join(' ');
}

const logger = {
    fatal: (...args: any[]) => {
        if (levels[loglevel] <= levels.FATAL) pinoLogger.fatal('SXM LOG: ' + argsToString(args));
    },
    error: (...args: any[]) => {
        if (levels[loglevel] <= levels.ERROR) pinoLogger.error('SXM LOG: ' + argsToString(args));
    },
    warn: (...args: any[]) => {
        if (levels[loglevel] <= levels.WARN) pinoLogger.warn('SXM LOG: ' + argsToString(args));
    },
    info: (...args: any[]) => {
        if (levels[loglevel] <= levels.INFO) pinoLogger.info('SXM LOG: ' + argsToString(args));
    },
    debug: (...args: any[]) => {
        if (levels[loglevel] <= levels.DEBUG) pinoLogger.debug('SXM LOG: ' + argsToString(args));
    },
    trace: (...args: any[]) => {
        if (levels[loglevel] <= levels.TRACE) pinoLogger.trace('SXM LOG: ' + argsToString(args));
    },
};
/* eslint-enable */

export default logger;
